/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
  padding-left: 0;
}

@media all and (min-width: 992px) {
  .sidebar .nav>li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
  }
}

.card.card-upgrade .card-category {
  max-width: 530px;
  margin: 0 auto;
}

.offline-doc .page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #ffffff;
  position: relative; }
  .offline-doc .page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .offline-doc .page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFFFFF;
    padding: 0 15px;
    width: 100%;
    max-width: 880px; }
  .offline-doc .page-header footer {
    position: absolute;
    bottom: 0;
    width: 100%; }
  .offline-doc .page-header .container {
    height: 100%;
    z-index: 1; }
  .offline-doc .page-header .category,
  .offline-doc .page-header .description {
    color: rgba(255, 255, 255, 0.8); }
  .offline-doc .page-header.page-header-small {
    min-height: 60vh;
    max-height: 440px; }
  .offline-doc .page-header.page-header-mini {
    min-height: 40vh;
    max-height: 340px; }
  .offline-doc .page-header .title {
    margin-bottom: 15px; }
  .offline-doc .page-header .title + h4 {
    margin-top: 10px; }
  .offline-doc .page-header:after, .offline-doc .page-header:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: ""; }
  .offline-doc .page-header:before {
    background-color: rgba(0, 0, 0, 0.8); }

.card-pricing {
    position: relative;
    z-index: 1;
    box-shadow: none;
    min-height: 400px;
}
    .card-pricing .card-body {
        overflow: hidden;
        padding: 0;
    }
        .card-pricing .card-body .card-title {
            position: relative;
            font-size: 5em;
            font-weight: 900;
            color: #222a42;
            text-transform: lowercase;
            margin-left: -5px;
            z-index: 1;
        }
        .card-pricing .card-body img {
            position: absolute;
            border-radius: 0.2857rem;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 0;
        }
.card-img, .card-img-bottom, .card-img-top {
    flex-shrink: 0;
    width: 100%;
}

img {
    max-width: 100%;
    border-radius: 0.2857rem;
}

img, svg {
    vertical-align: middle;
}

img {
    border-style: none;
}
.card-pricing ul {
    list-style: none;
    padding: 0;
    max-width: 240px;
    margin: 80px auto 40px;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
}
.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.card-pricing ul li {
    color: hsla(0,0%,100%,.9);
    background: transparent;
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    padding: 5px 0;
    border: none;
}
.card-pricing .card-body .card-prices .text-on-front {
    position: relative;
    z-index: 2;
    color: hsla(0,0%,100%,.9);
    font-size: 3.6em;
    font-weight: 900;
    text-align: center;
}
.card-pricing.card-primary .card-body .card-prices .text-on-back {
    color: rgba(225,78,202,.1);
}
.card-pricing.card-success .card-body .card-prices .text-on-back {
    color: rgba(0,242,195,.1);
}
.card-pricing.card-warning .card-body .card-prices .text-on-back {
    color: rgba(255,141,114,.1);
}
.card-pricing.card-danger .card-body .card-prices .text-on-back {
    color: rgba(253,93,147,.1);
}

.card-pricing .card-body .card-prices .text-on-back {
    position: relative;
    z-index: 1;
    font-size: 9.5em;
    text-align: center;
    margin-top: -160px;
    font-weight: 900;
    color: rgba(38,49,72,.1);
}
.card-pricing .card-body .card-prices .plan {
    font-weight: 600;
    font-size: 1em;
    margin-top: -80px;
    text-align: center;
    color: hsla(0,0%,100%,.9);
}
.card-pricing.card-white {
    background: #fff;
}
    .card-pricing.card-white .card-body .card-title {
        color: #fff;
    }
    .card-pricing.card-white .list-group-item {
        background: transparent;
    }
    .card-pricing.card-white .card-body .card-prices .plan, .card-pricing.card-white .card-body .card-prices .plan span, .card-pricing.card-white .card-body .card-prices .text-on-front, .card-pricing.card-white .card-body .card-prices .text-on-front span, .card-pricing.card-white ul li {
        color: #263148;
    }
.card-pricing.card-raised {
    z-index: 3;
    box-shadow: 0 0 40px 15px rgb(38 49 72 / 30%);
    padding: 20px 0;
    margin-top: -20px;
    border-radius: 0.2857rem;
}

.full-page.lock-page, .full-page.login-page, .full-page.pricing-page, .full-page.register-page {
    min-height: 100vh;
    background: radial-gradient(#1e1e2f,#1e1e24);
}
.full-page > .landingPageContent {
    padding-bottom: 150px;
    padding-top: 150px;
}

.full-page > .landingPageContent, .full-page > .footer {
    position: relative;
    z-index: 4;
}

.padding-right {
    padding-right: 0;
}
.no-padding {
    padding: 0;
}

.padding-left {
    padding-left: 0;
}

.margin-top-percent-50{
    margin-top:50%
}

.ReactTable {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: initial;
}
    .ReactTable * {
        box-sizing: border-box;
    }
    .ReactTable .-pagination {
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        padding: 3px;
    }
        .ReactTable .-pagination .-next, .ReactTable .-pagination .-previous {
            flex: 1 1;
            text-align: center;
        }
        .ReactTable .-pagination .-btn {
            font-weight: 600;
            font-size: 1em;
            line-height: 1.35em;
            border: none;
            position: relative;
            overflow: hidden;
            margin: 4px 1px;
            border-radius: 0.4285rem;
            padding: 11px 40px;
            cursor: pointer;
            background: #1d8cf8;
            background-image: linear-gradient(to bottom left,#1d8cf8,#3358f4,#1d8cf8);
            background-size: 210% 210%;
            background-position: 100% 0;
            background-color: #1d8cf8;
            transition: all .15s ease;
            box-shadow: none;
            color: #fff;
            width: 100%;
            outline: none !important;
        }
        .ReactTable .-pagination .-center {
            flex: 1.5 1;
            text-align: center;
            margin-bottom: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;
        }
.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
}
.react-select .react-select__control, .react-select .react-select__control:hover {
    border: 1px solid #2b3553;
    box-shadow: none;
    background-color: initial;
}

.react-select .react-select__control {
    border-radius: 4px;
    height: calc(2.25rem + 2px);
    box-sizing: border-box;
    border-collapse: initial;
    display: table;
    width: 100%;
}
.css-yk16xz-control {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    outline: 0px !important;
}
.react-select .react-select__control .react-select__indicators, .react-select .react-select__control .react-select__value-container {
    display: table-cell;
}
 
.css-1hwfws3 {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
.react-select.info .react-select__control .react-select__value-container--has-value .react-select__multi-value__label, .react-select.info .react-select__control .react-select__value-container--has-value .react-select__single-value {
    color: #1d8cf8;
}

.react-select .react-select__control .react-select__value-container--has-value .react-select__single-value {
    top: 48%;
    color: #e14eca;
}
 
.css-1uccc91-singleValue {
    color: rgb(51, 51, 51);
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
}
.css-1g6gooi {
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    visibility: visible;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
}
.react-select .react-select__control .react-select__indicators {
    width: 60px;
    padding-right: 10px;
    align-items: flex-end;
    vertical-align: middle;
    text-align: right;
}

.react-select .react-select__control .react-select__indicators, .react-select .react-select__control .react-select__value-container {
    display: table-cell;
}
 
.css-1wy0on6 {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}
.react-select .react-select__control .react-select__indicators .react-select__indicator-separator {
    display: none;
}
 
.css-1okebmr-indicatorSeparator {
    align-self: stretch;
    background-color: rgb(204, 204, 204);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}
.react-select .react-select__control .react-select__indicators .react-select__indicator {
    padding: 0;
    display: inline-block;
}
 
.css-tlfecz-indicatorContainer {
    color: rgb(204, 204, 204);
    display: flex;
    padding: 8px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
}
.react-select .react-select__control .react-select__indicators .react-select__dropdown-indicator > * {
    display: none;
}
 
.css-19bqh2r {
    display: inline-block;
    fill: currentcolor;
    line-height: 1;
    stroke: currentcolor;
    stroke-width: 0;
}
.react-select .react-select__control .react-select__indicators .react-select__dropdown-indicator:after {
    transition: all .15s ease 0s;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative;
    content: "";
}
.ReactTable .-pagination input, .ReactTable .-pagination select, .ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
    background-clip: padding-box;
    border: 1px solid #2b3553;
    background-color: initial;
    border-radius: 0.4285rem;
    color: hsla(0,0%,100%,.8);
    line-height: normal;
    font-size: .8571em;
    transition: color .3s ease-in-out,border-color .3s ease-in-out,background-color .3s ease-in-out;
    box-shadow: none;
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
    height: calc(1.8125rem + 2px);
    padding: 0.25rem 0.5rem;
}
.ReactTable .-pagination .-next, .ReactTable .-pagination .-previous {
    flex: 1 1;
    text-align: center;
}
.ReactTable .-pagination .-btn[disabled] {
    opacity: .5;
    cursor: not-allowed;
}
.ReactTable .rt-table {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
}
.ReactTable .rt-thead {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
    user-select: none;
}
    .ReactTable .rt-thead .rt-tr {
        text-align: center;
    }

.ReactTable .rt-tr {
    flex: 1 0 auto;
    display: inline-flex;
}
.ReactTable .rt-thead .rt-td.-cursor-pointer, .ReactTable .rt-thead .rt-th.-cursor-pointer {
    cursor: pointer;
}

.ReactTable .rt-thead .rt-th {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    border: 0;
    color: hsla(0,0%,100%,.7);
}

.ReactTable .rt-thead .rt-resizable-header {
    overflow: visible;
}

.ReactTable .rt-thead .rt-td, .ReactTable .rt-thead .rt-th {
    font-size: 1.063rem;
    text-align: left;
    font-weight: 300;
    line-height: 2.5em !important;
    padding: 5px;
    line-height: normal;
    position: relative;
    transition: box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
    box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-td, .ReactTable .rt-th {
    flex: 1 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width,min-width,padding,opacity;
    margin: auto;
}
.ReactTable .rt-thead .rt-resizable-header-content {
    overflow: hidden;
    text-overflow: ellipsis;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:before {
    right: 1em;
    content: "\2191";
}

.ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after, .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:before {
    position: absolute;
    display: block;
    opacity: .3;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after {
    right: 0.5em;
    content: "\2193";
    top: 0.4em;
}
.ReactTable .rt-tbody {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.ReactTable.-striped .rt-tr.-odd {
    background: rgba(0,0,0,.03);
}

.ReactTable .rt-tbody .rt-tr, .ReactTable .rt-thead.-filters .rt-tr {
    border-color: #dee2e6 hsla(0,0%,100%,.1) hsla(0,0%,100%,.1);
    border-top: 1px solid hsla(0,0%,100%,.1);
}

 
.ReactTable .rt-td {
    padding: 12px 7px;
    vertical-align: middle;
    color: hsla(0,0%,100%,.7) !important;
}

.ReactTable .rt-td, .ReactTable .rt-th {
    flex: 1 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width,min-width,padding,opacity;
    margin: auto;
}
.ReactTable .actions-right {
    text-align: right;
}

.card .table tr td p.title {
    padding-top: 7px;
    margin-bottom: 0;
}

.questionColor {
    color: hsla(0,0%,100%,.8) !important;
}

.title {
    font-weight: 600;
}
.card-register {
    overflow: hidden;
}
    .card-register .card-header {
        padding: 0 0 50px;
        overflow: hidden;
    }
    .card-register .card-img {
        position: absolute;
        left: 0;
        top: -15%;
        width: 70%;
    }
    .card-register.card-white .card-header .card-title {
        color: #fff;
    }

    .card-register .card-header .card-title {
        position: relative;
        /*font-size: 5em;*/
        font-weight: 900;
        color: #222a42;
        z-index: 1;
    }
.full-page.lock-page, .full-page.login-page, .full-page.pricing-page, .full-page.register-page {
    min-height: 100vh;
    background: radial-gradient(#1e1e2f,#1e1e24);
}
.full-page > .content {
    padding-bottom: 150px;
    padding-top: 150px;
}

.full-page > .landingPageContent, .full-page > .footer {
    position: relative;
    z-index: 4;
}