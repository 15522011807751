.landingPageContent {
    padding: 78px 50px 30px 100px;
}
.landingPageCard {
    height: 500px;
}
.hidden {
    display: none;
}
.show {
    display: block;
}